import React from 'react'
import { graphql } from 'gatsby'

import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import MissionQuality from '../components/MissionQuality'
import MissionSteps from '../components/MissionSteps'
import Presentation from '../components/Presentation'

const CommentCaMarchePageTemplate = ({ missionQuality, missionSteps, dashboardPresentation }) => {
  return (
    <>
      <MissionQuality {...missionQuality} ctaLink={`${process.env.GATSBY_APP_URL}/signup?pType=FREELANCE`} />
      <MissionSteps {...missionSteps} />
      <Presentation {...dashboardPresentation} />
    </>
  )
}

const CommentCaMarchePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout isFree>
      <Helmet>
        <title>Inscrivez-vous sur notre plateforme et rejoignez la crème de la communauté d’experts de l’ingénierie.</title>
        <meta name="description" content="Créez votre profil en quelques minutes et gagnez l’accès à des dizaines de missions d’ingénierie parmi les plus grands. Bénéficiez gratuitement d’avantages auprès de nos partenaires." />
        {/* Open Graph */}
        <meta name="og:title" content="Artelink - Inscrivez-vous sur notre plateforme et rejoignez la crème de la communauté d’experts de l’ingénierie." />
        <meta name="og:image" content="" />
        <meta name="og:url" content="" />
        <meta name="og:description" content="Créez votre profil en quelques minutes et gagnez l’accès à des dizaines de missions d’ingénierie parmi les plus grands. Bénéficiez gratuitement d’avantages auprès de nos partenaires." />
      </Helmet>

      <CommentCaMarchePageTemplate {...frontmatter} />
    </Layout>
  )
}

export default CommentCaMarchePage

export const pageQuery = graphql`
  query CommentCaMarchePage {
    markdownRemark(frontmatter: { templateKey: { eq: "comment-ca-marche-page" } }) {
      frontmatter {
        missionQuality {
          title
          subTitle
          missionExamples {
            publicURL
          }
          cta
        }
        missionSteps {
          title
          subTitle
          steps {
            title
            text
          }
        }
        dashboardPresentation {
          title
          subTitle
          image {
            publicURL
            extension
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
          features
          preCtaText
          cta
        }
      }
    }
  }
`
