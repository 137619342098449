import React from 'react'
import ReactMarkdown from 'react-markdown'

import Button from '../Button'
import ContentWrapper from '../ContentWrapper'
// import TypeformLink from '../TypeformLink'

import * as s from './style.module.sass'

const MissionQuality = ({ title, subTitle, missionExamples, cta, ctaLink }) => {
  return (
    <div className={s.wrapper}>
      <ContentWrapper>
        <div className={s.textContainer}>
          <h1>
            <ReactMarkdown>{title}</ReactMarkdown>
          </h1>
          <p>
            {subTitle}
          </p>
        </div>
        <div className={s.examples}>
          {
            missionExamples.map((img, i) => (
              <div className={s[`img${i}`]} key={i}>
                <img 
                  src={img.publicURL}
                  alt={`exemple mission ${i}`}
                />
              </div>
            ))
          }
        </div>
        <div className={s.buttonContainer}>
          <a href={ctaLink}>
          {/* <TypeformLink url={ctaLink}> */}
            <Button icon="rightArrow">{cta}</Button>
          {/* </TypeformLink> */}
          </a>
        </div>
      </ContentWrapper>
    </div>
  )
}

export default MissionQuality
